<template>
  <div :class="$style.root">
    <div :class="$style.image" :data-zoomable="$device.isDesktop ? '' : null">
      <slot name="image" />
    </div>
    <div :class="$style.caption">
      <slot name="caption" />
    </div>
  </div>
</template>


<style module>
.root {
  composes: font-helvetica font-size-small from global;
  position: relative;
}

.caption {
  z-index: 2;
  position: relative;

  padding-left: var(--layout--image-with-caption--padding-left, var(--unit--default));
  padding-right: var(--layout--image-with-caption--padding-right, var(--unit--default));

  margin-top: var(--padding--element--top);
}

.image[data-zoomable] {
  transition: var(--scale--transition);
}

.root:hover .image[data-zoomable] {
  transform: var(--scale);
}
</style>
